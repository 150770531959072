/*
|--------------------------------------------------------------------------
| Users management features and utilities composable
|--------------------------------------------------------------------------
|
| This composable JS file contains all common properties and methods
| used in the different views or components for the Items management
| of the application.
*/

// Import Vue.js Framework functions
import { reactive } from 'vue'
import { computed } from 'vue'

// import own utility function library
import useCrudComp from './useCrudComp.js'
import useErrorsComp from '@/composables/useErrorsComp.js'
import useArrayUtilsComp from '@/composables/useArraysUtilsComp.js'

export default function useItemsComp() {

    // define variable with the needed utils functions
    const useCrud = useCrudComp()
    const useErrors = useErrorsComp()
    const useArrayUtils = useArrayUtilsComp()


    // define the reactive variables
    const state = reactive({

        form: {
            body: {
                id: null,
                name: '',
                description: '',
            },
            status: null,
            resources: null,
            loading: true,

            response: {
                message: null,
                alert: null,
            },
        },

        pagination: {
            meta: {
                currentPage: 0,
                from: 0,
                lastPage: 0,
                perPage: 0,
                to: 0,
                total: 0,
                requestQtyPerPage: 0,
                requestedPage: 0,
            },
            links: {
                prev: null,
                next: null,
            },
        },

        selectedItems : computed(() => {
            return state.form.resources
        })

    })


    /* ************************************************************************ */
        const tableSort = reactive({
            asc: true,
            col: '',
        })

        // Function to sort Array of Resources
        function sortResources(byKey, type ) {
            useArrayUtils.sortArrayForTables( state.form.resources, byKey, type, tableSort )
        }
    /* ************************************************************************ */



    // Flush all user's attributes
    function flushAttributes() {
        state.form.body.id = null
        state.form.body.name = ''
        state.form.body.description = ''
    }


    function getItems() {

        state.form.loading = true

        return useCrud.getAllResources({
            url: 'items'
        })
        .then((res) => {
            state.form.resources = res.data.data

            state.pagination.meta.total = res.data.meta.total
            state.pagination.meta.curentPage = res.data.meta.current_page
            state.pagination.meta.lastPage = res.data.meta.last_page
            state.pagination.meta.perPage = res.data.meta.per_page
            state.pagination.meta.to = res.data.meta.to
            state.pagination.meta.from = res.data.meta.from

            state.pagination.links.prev = res.data.links.prev
            state.pagination.links.next = res.data.links.next

            state.form.loading = false
            state.form.status = 'success'
        })
    }

    function getResourcesPaginate() {

        state.form.loading = true

        let nextPage = state.pagination.meta.requestedPage
        let nbPerPage = state.pagination.meta.requestQtyPerPage
        let requestUrl = 'items/paginate/' + nbPerPage + '?page=' + nextPage

        return useCrud.getResourcesWithPagination({
            url: requestUrl
        })
        .then((res) => {
            state.form.resources = res.data.data

            state.pagination.meta.total = res.data.meta.total
            state.pagination.meta.curentPage = res.data.meta.current_page
            state.pagination.meta.lastPage = res.data.meta.last_page
            state.pagination.meta.perPage = res.data.meta.per_page
            state.pagination.meta.to = res.data.meta.to
            state.pagination.meta.from = res.data.meta.from

            state.pagination.links.prev = res.data.links.prev
            state.pagination.links.next = res.data.links.next


            state.form.loading = false
            state.form.status = 'success'
        })

    }


    // Store new created resource to backend
    function storeResource() {

        useErrors.useResetErrors()
        state.form.status = 'saving'

        return useCrud.storeResource({
            url: 'items',
            resource: state.form.body
        })
        .then((res) => {
            state.form.resources.push(res.data.resource)

            state.form.status = 'success'
        })
    }


    function showResource(id) {

        state.form.loading = true

        return useCrud.showResource({
            url: 'items/' + id,
            resource: state.form.body
        })
        .then((res) => {
            state.form.loading = false
            state.form.status = 'success'

            return res.data.resource
        });
    }

    // Update modified resource in backend
    function updateResource() {

        useErrors.useResetErrors()
        state.form.status = 'updating'

        let id = state.form.body.id

        return useCrud.updateResource({
            url: 'items/' + id,
            resource: state.form.body
        })
        .then((res) => {
            var index = state.form.resources.findIndex(x => x.id === res.data.resource.id)

            // if resource not existing in array
            if (index < 0) {
                // add resource to array
                state.form.resources.push(res.data.resource)
            } else {
                // update resource in array
                state.form.resources.splice(index, 1, res.data.resource)
            }

            state.form.status = 'success'
            state.form.response.message = res.data.message
        })
    }

    // Delete resource in backend
    function deleteResource(resource) {

        return useCrud.deleteResource({
            url: 'items/' + resource.id,
            resource: resource.id
        })
        .then(() => {
            var index = state.form.resources.findIndex(x => x.id === resource.id)
            state.form.resources.splice(index, 1)

            state.form.status = 'success'
        })

    }





    return {
        useErrors,
        state,
        flushAttributes,
        sortResources,
        getItems,
        getResourcesPaginate,
        storeResource,
        showResource,
        updateResource,
        deleteResource,
    }

}
