<template>

    <div class="container my-3">

        <h2>
            <i class="bi bi-shop-window"></i>
            {{ $t('titles.itemList') }}
        </h2>

        <div class="row my-3">

            <div class="col">
                <button
                    v-if="masterUser()"
                    type="button"
                    tag="button"
                    class="btn btn-outline-primary btn-sm mx-3"
                    @click.prevent="createResource()"
                  >
                    <i class="bi bi-pencil-square"></i>
                    {{ $t('form.actions.newItem') }}
                </button>
            </div>
        </div>


        <div class="row">
            <div class="col align-self-start">
                <div class="ms-3 my-3">

                </div>
            </div>

            <div class="col align-self-center">
                <ul class="justify-content-end nav">

                    <NavItem
                        :quantity="10"
                        :selected="useItems.state.pagination.meta.perPage"
                        @setNbPerPageForPagination="(n) => setNbPerPageForPagination(n)"
                    />

                    <span class="align-self-center mx-1"> | </span>

                    <NavItem
                        :quantity="20"
                        :selected="useItems.state.pagination.meta.perPage"
                        @setNbPerPageForPagination="(n) => setNbPerPageForPagination(n)"
                    />

                    <span class="align-self-center mx-1"> | </span>

                    <NavItem
                        :quantity="useItems.state.pagination.meta.total"
                        :selected="useItems.state.pagination.meta.perPage"
                        @setNbPerPageForPagination="(n) => setNbPerPageForPagination(n)"
                    />

                </ul>
            </div>
        </div>

        <div class="d-flex justify-content-center">
            <div v-show="useItems.state.form.loading" class="spinner-border text-primary" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>


        <!-- Display server error messages, from server validation -->
        <!-- Diplay alert message received from backend -->
        <MyModal
            v-bind:idProps="state.modal.item.alert"
        >
            <!--    Content for header slot     -->
            <template v-slot:header>
                {{ state.modal.item.header }}
            </template>

            <template v-slot:body>

                <AltertMessage
                    v-bind:data="useItems.state.form.response"
                ></AltertMessage>

            </template>

        </MyModal>


        <div
            v-if="!useItems.state.form.loading"
        >

            <div class="table-responsive">
                <table class="table">
                    <thead>
                        <tr>
                            <th scope="col">
                                <TableHeaderItem
                                    :titleName="$t('models.item.id')"
                                    :sortArgs="useItems.tableSort"
                                    :headerName="'id'"
                                    @sort-event="useItems.sortResources('id', 'number')"
                                />
                            </th>

                            <th scope="col">
                                <TableHeaderItem
                                    :titleName="$t('models.item.name')"
                                    :sortArgs="useItems.tableSort"
                                    :headerName="'name'"
                                    @sort-event="useItems.sortResources('name', 'string')"
                                />
                            </th>

                            <th scope="col">
                                <TableHeaderItem
                                    :titleName="$t('models.item.description')"
                                    :sortArgs="useItems.tableSort"
                                    :headerName="'description'"
                                    @sort-event="useItems.sortResources('description', 'string')"
                                />
                            </th>


                            <th scope="col">{{ $t('models.item.prices') }}</th>
                            <th scope="col">{{ $t('models.item.show') }}</th>
                            <template v-if="masterUser()">
                                <th scope="col">{{ $t('models.item.edit') }}</th>
                                <th scope="col">{{ $t('models.item.delete') }}</th>
                            </template>

                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="item in useItems.state.selectedItems"
                            v-bind:key="item"
                            v-bind:class="(item.id === state.selectedItem.id) ? 'table-info' : 'table-default'"
                        >
                            <td>{{ item.id }}</td>
                            <td>
                                <a href="#" @click.prevent="showResource(item)">
                                    {{ item.name }}
                                </a>
                            </td>

                            <td>{{ item.description }}</td>
                            <td>
                                <a
                                    class="icon-edit"
                                    href="#"

                                    @click.prevent="getPrices(item)"
                                >
                                    <i class="bi bi-cash-coin"></i>
                                </a>
                            </td>
                            <td>
                                <a
                                    class="icon-edit"
                                    href="#"

                                    @click.prevent="showResource(item)"
                                >
                                    <i class="bi bi-eye"></i>
                                </a>
                            </td>
                            <template v-if="masterUser()">
                                    <td>
                                    <a
                                        class="icon-edit"
                                        href="#"

                                        @click.prevent="editResource(item)"
                                    >
                                        <i class="bi bi-pencil-fill"></i>
                                    </a>
                                </td>
                                <td>
                                    <a
                                        class="icon-edit"
                                        href="#"
                                        @click.prevent="deleteResource(item)"
                                    >
                                        <i class="bi bi-trash"></i>
                                    </a>
                                </td>
                            </template>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div class="row my-3 py-3">
            <nav aria-label="Page navigation example" class="my-3">
                <ul class="pagination justify-content-center mb-3">
                    <li class="page-item">
                        <a
                            class="page-link"
                            href="#"
                            aria-label="Previous"
                            @click.prevent="getPreviousResourcesPagination()"
                        >
                            <span aria-hidden="true">&laquo;</span>
                        </a>
                    </li>

                    <li
                        v-for="page in useItems.state.pagination.meta.lastPage"
                        v-bind:key="page"
                        class="page-item"
                        v-bind:class="page === useItems.state.pagination.meta.curentPage ? 'active' : ''"
                        @click.prevent="getPageResourcesPagination(page)"
                    >
                        <a class="page-link" href="#">{{ page }}</a>
                    </li>

                    <li class="page-item">
                        <a
                            class="page-link"
                            href="#"
                            aria-label="Next"
                            @click.prevent="getNextResourcesPagination()"
                        >
                            <span aria-hidden="true">&raquo;</span>
                        </a>
                    </li>
                </ul>
            </nav>
        </div>


        <MyModal
            v-bind:idProps="state.modal.item.show"
            @click.prevent="state.modal.item.componentShow.toggle()"
        >
            <!--    Content for header slot     -->
            <template v-slot:header>
                {{ state.modal.item.header }}
            </template>

            <template v-slot:body>

                <table class="table">
                    <thead>
                        <th scope="col">Index</th>
                        <th scope="col">Item</th>
                        <th scope="col">typeOf</th>
                    </thead>
                    <tbody>
                        <tr
                            v-for="(item, index) in state.itemToShow"
                            v-bind:key="item"
                        >
                            <td>{{ index }}</td>
                            <td>{{ item }}</td>
                            <td>{{ typeof(item) }}</td>
                        </tr>
                    </tbody>
                </table>
            </template>
        </MyModal>


        <MyModal
            v-bind:idProps="state.modal.item.edit"
        >
            <!--    Content for header slot     -->
            <template v-slot:header>
                {{ state.modal.item.header }}
            </template>

            <template v-slot:body>

                <!-- Display server error message from database -->
                <ExceptionMessage
                    v-bind:exception="useItems.useErrors.state.exception"
                    v-bind:exceptionMessage="useItems.useErrors.state.exceptionMessage"
                ></ExceptionMessage>


                <div class="form-floating mb-3">
                    <input
                        type="text"
                        class="form-control rounded-4"
                        id="name"
                        v-bind:class="formUtilsComp.isFieldEmpty(useItems.state.form.body.name) ? 'is-invalid' : 'is-valid'"
                        @keyup.enter="saveResource()"
                        placeholder="name"
                        v-model="useItems.state.form.body.name"
                    >
                    <label for="name">{{ $t('models.item.name') }}</label>
                    <!-- Diplay frontend application alert message -->
                    <div v-if="formUtilsComp.isFieldEmpty(useItems.state.form.body.name)" class="text-info text-sm">
                        {{ $t('models.item.validations.name') }}
                    </div>
                    <!-- Diplay alert message received from backend -->
                    <ValidationErrors v-bind:errors="useItems.useErrors.state.errors.name"></ValidationErrors>
                </div>

                <div class="form-floating mb-3">
                    <input
                        type="text"
                        class="form-control rounded-4"
                        id="description"
                        v-bind:class="formUtilsComp.isFieldEmpty(useItems.state.form.body.description) ? 'is-invalid' : 'is-valid'"
                        @keyup.enter="saveResource()"
                        placeholder="description"
                        v-model="useItems.state.form.body.description"
                    >
                    <label for="description">{{ $t('models.item.description') }}</label>
                    <!-- Diplay frontend application alert message -->
                    <div v-if="formUtilsComp.isFieldEmpty(useItems.state.form.body.description)" class="text-info text-sm">
                        {{ $t('models.item.validations.description') }}
                    </div>
                    <!-- Diplay alert message received from backend -->
                    <ValidationErrors v-bind:errors="useItems.useErrors.state.errors.description"></ValidationErrors>
                </div>



                <button
                    @click="saveResource()"
                    class="w-100 mb-2 btn btn-lg rounded-4 btn-primary"
                >
                    {{ $t('form.actions.save') }}
                </button>


            </template>


        </MyModal>


        <h4>
            <i class="bi bi-cash-coin"></i>
            {{ $t('titles.priceList') }}
        </h4>

        <div v-if="Object.keys(state.selectedItem).length > 0">

        <!--
            <p>{{ state.selectedItem.id }}</p>
            <p>{{ state.selectedItem.name }}</p>
        -->
            <p>{{ state.selectedItem.description }}</p>

            <div class="row my-3">

                <div class="col">
                    <button
                        v-if="masterUser()"
                        type="button"
                        tag="button"
                        class="btn btn-outline-primary btn-sm mx-3"
                        @click.prevent="createPrice(state.selectedItem)"
                      >
                        <i class="bi bi-pencil-square"></i>
                        {{ $t('form.actions.newPrice') }}
                    </button>
                </div>
            </div>
        </div>


        <!-- Display server error messages, from server validation -->
        <!-- Diplay alert message received from backend -->
        <MyModal
            v-bind:idProps="state.modal.price.alert"
        >
            <!--    Content for header slot     -->
            <template v-slot:header>
                {{ state.modal.price.header }}
            </template>

            <template v-slot:body>

                <AltertMessage
                    v-bind:data="usePrices.state.form.response"
                ></AltertMessage>

            </template>
        </MyModal>




        <div v-if="Object.keys(state.selectedItem).length > 0">

            <div class="table-responsive my-5">
                <table class="table">
                    <thead>
                        <tr>

                            <th scope="col">{{ $t('models.price.id') }}</th>
                            <th scope="col">{{ $t('models.price.item_id') }}</th>
                            <th scope="col">{{ $t('models.item.name') }}</th>
                            <th scope="col">{{ $t('models.price.amount') }}</th>
                            <th scope="col">{{ $t('models.price.validity_from') }}</th>
                            <th scope="col">{{ $t('models.price.validity_to') }}</th>
                            <th scope="col">{{ $t('models.price.edit') }}</th>
                            <th scope="col">{{ $t('models.price.delete') }}</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr
                            v-for="price in usePrices.state.selectedPrices"
                            v-bind:key="price"
                            class=" align-middle"
                        >
                            <td>{{ price.id }}</td>
                            <td>{{ state.selectedItem.id }}</td>
                            <td>{{ state.selectedItem.name }}</td>
                            <td>{{ price.amount }}</td>
                            <td>{{ dateTime.showDate(new Date(price.validity_from)) }}</td>
                            <td>{{ dateTime.showDate(new Date(price.validity_to)) }}</td>

                            <td>


                                <a
                                    class="icon-edit fs-4"
                                    href="#"
                                    @click.prevent="editPrice(price)"
                                >
                                    <i class="bi bi-pencil-fill"></i>
                                </a>

                            </td>
                            <td>

                                <a
                                    class="icon-edit fs-4"
                                    href="#"
                                    @click.prevent="deletePrice(price, state.selectedItem.id)"
                                >
                                    <i class="bi bi-trash"></i>
                                </a>

                            </td>

                        </tr>
                    </tbody>
                </table>
            </div>


        </div>

        <MyModal
            v-bind:idProps="state.modal.price.edit"
        >
            <!--    Content for header slot     -->
            <template v-slot:header>
                {{ state.modal.price.header }}
            </template>

            <template v-slot:body>

                <!-- Display server error message from database -->
                <ExceptionMessage
                    v-bind:exception="usePrices.useErrors.state.exception"
                    v-bind:exceptionMessage="usePrices.useErrors.state.exceptionMessage"
                ></ExceptionMessage>


                <div class="form-floating mb-3">
                    <input
                        type="number"
                        step="0.01"
                        class="form-control rounded-4"
                        id="amount"
                        v-bind:class="formUtilsComp.isFieldEmpty(usePrices.state.form.body.amount) ? 'is-invalid' : 'is-valid'"
                        @keyup.enter="savePrice()"
                        placeholder="amount"
                        v-model="usePrices.state.form.body.amount"
                    >
                    <label for="amount">{{ $t('models.price.amount') }}</label>
                    <!-- Diplay frontend application alert message -->
                    <div v-if="formUtilsComp.isFieldEmpty(usePrices.state.form.body.amount)" class="text-info text-sm">
                        {{ $t('models.price.validations.amount') }}
                    </div>
                    <!-- Diplay alert message received from backend -->
                    <ValidationErrors v-bind:errors="usePrices.useErrors.state.errors.amount"></ValidationErrors>
                </div>


                <div class="form-floating mb-3">
                    <Datepicker
                        id="validity_from"
                        :placeholder="$t('models.price.validity_from')"
                        v-model="state.newFromDate"
                        :state="!formUtilsComp.isFieldEmpty(usePrices.state.form.body.validity_from)"
                        :previewFormat="format"
                        @keyup.enter="savePrice()"
                        :enableTimePicker="false"
                        locale="fr"
                        :format="format"
                        name="date-picker"
                        @internalModelChange="handleInternalChangeValidityFrom"
                        required
                        vertical
                    ></Datepicker>

                    <!-- Diplay frontend application alert message -->
                    <div v-if="formUtilsComp.isFieldEmpty(usePrices.state.form.body.validity_from)" class="text-info text-sm">
                        {{ $t('models.price.validations.validity_from') }}
                    </div>
                    <!-- Diplay alert message received from backend -->
                    <ValidationErrors v-bind:errors="usePrices.useErrors.state.errors.validity_from"></ValidationErrors>
                </div>


                <div class="form-floating mb-3">
                    <Datepicker
                        id="validity_to"
                        :placeholder="$t('models.price.validity_to')"
                        v-model="state.newToDate"
                        :state="!formUtilsComp.isFieldEmpty(usePrices.state.form.body.validity_to)"
                        :previewFormat="format"
                        @keyup.enter="savePrice()"
                        :enableTimePicker="false"
                        locale="fr"
                        :format="format"
                        name="date-picker"
                        @internalModelChange="handleInternalChangeValidityTo"
                        required
                        vertical
                    ></Datepicker>

                    <!-- Diplay frontend application alert message -->
                    <div v-if="formUtilsComp.isFieldEmpty(usePrices.state.form.body.validity_to)" class="text-info text-sm">
                        {{ $t('models.price.validations.validity_to') }}
                    </div>
                    <!-- Diplay alert message received from backend -->
                    <ValidationErrors v-bind:errors="usePrices.useErrors.state.errors.validity_to"></ValidationErrors>
                </div>





                <button
                    @click="savePrice()"
                    class="w-100 mb-2 btn btn-lg rounded-4 btn-primary"
                >
                    {{ $t('form.actions.save') }}
                </button>


            </template>


        </MyModal>









    </div>

</template>


<script>
// import Vue.js framework functions
import { ref } from 'vue'
import { reactive } from 'vue'
import { onMounted } from 'vue'

// import external functionalities
import { Modal } from 'bootstrap';
import { useI18n } from 'vue-i18n'

// import application's components
import MyModal from '@/components/MyModal.vue'
import TableHeaderItem from '@/components/table/TableHeaderItem.vue'
import NavItem from '@/components/navigation/NavItem.vue'

// import Datepicker
import Datepicker from 'vue3-date-time-picker';
import 'vue3-date-time-picker/dist/main.css'

// import composables files
import useAuthComp from '@/composables/useAuthComp.js'
import useObjectsUtils from '@/composables/useObjectsUtils.js'
import useDateTimeUtilsComp from '@/composables/useDateTimeUtilsComp.js'
import useFormUtilsComp from '@/composables/useFormUtilsComp.js'
import useItemsComp from '@/composables/useItemsComp.js'
import usePricesComp from '@/composables/usePricesComp.js'

// import informations and errors messages components
import AltertMessage from '@/components/messages/altertMessage.vue'
import ExceptionMessage from '@/components/messages/exceptionMessage.vue'
import ValidationErrors from '@/components/messages/validationErrors.vue'

export default {

    name: 'items',

    components: {
        MyModal,
        TableHeaderItem,
        NavItem,
        AltertMessage,
        ValidationErrors,
        ExceptionMessage,
        Datepicker,
    },

    setup() {

        const { masterUser } = useAuthComp()

        const objUtils = useObjectsUtils()
        const dateTime = useDateTimeUtilsComp()
        const useItems = useItemsComp()
        const usePrices = usePricesComp()
        const formUtilsComp = useFormUtilsComp()

        const { t } = useI18n({ useScope: 'global' })

        const utc = ref('preserve');

        const state = reactive({
            modal: {
                item: {
                    edit: 'editItemModal',
                    show: 'showItemModal',
                    alert: 'alertItemModal',
                    header: '',
                    action: '',
                    componentEdit: null,
                    componentShow: null,
                    componentAlert: null,
                },
                price: {
                    edit: 'editPriceModal',
                    show: 'showPriceModal',
                    alert: 'alertPriceModal',
                    header: '',
                    action: '',
                    componentEdit: null,
                    componentShow: null,
                    componentAlert: null,
                },
            },
            itemToShow: {},
            itemInfo: '',
            selectedItem: {},
            newFromDate: new Date(),
            newToDate: new Date(),
            newPrice: 0,

        })



        function getPrices(item) {
            //console.log(item.id)

            // Copy item data to display for the prices table
            state.selectedItem = {}
            state.selectedItem.id = item.id
            state.selectedItem.name = item.name
            state.selectedItem.description = item.description

            usePrices.getPricesFromItemId(item.id)
        /*
            .then((res) => {
                console.log(res)

                if (res.length === 0) {
                    console.log('empty data array')
                }
                else {
                    console.log('data array not empty')
                }
            })
        */
        }

        // Save new created or updated resource
        function savePrice() {

            if ( usePrices.state.form.body.id == null ) {

                usePrices.storeResource()
                .then(() => {
                    // Close Modal component
                    state.modal.price.componentEdit.toggle()
                    //console.log('new price saved')
                })
                .catch((error) => {
                    usePrices.useErrors.useSetErrors(error.response.data)
                })
            } else {
                usePrices.updateResource()
                .then(() => {
                    // Close Modal component
                    state.modal.price.componentEdit.toggle()
                    //console.log('price updated')
                })
                .catch((error) => {
                    usePrices.useErrors.useSetErrors(error.response.data)
                })
            }

        }

        function deletePrice(resource) {
            if ( confirm(t('form.infos.delete.confirmation') + ` '${resource.amount}'`) ) {
                usePrices.deleteResource(resource)
            }
        }



        onMounted(() => {

            state.modal.item.componentEdit = new Modal(document.getElementById(state.modal.item.edit))
            state.modal.item.componentShow = new Modal(document.getElementById(state.modal.item.show))
            state.modal.item.componentAlert = new Modal(document.getElementById(state.modal.item.alert))

            // Requested quantity for resources pagination
            useItems.state.pagination.meta.requestQtyPerPage = 10

            state.modal.price.componentEdit = new Modal(document.getElementById(state.modal.price.edit))
            //state.modal.price.componentShow = new Modal(document.getElementById(state.modal.price.show))
            state.modal.price.componentAlert = new Modal(document.getElementById(state.modal.price.alert))

            getAllItems()
        })

        function setNbPerPageForPagination(nb) {
            useItems.state.pagination.meta.requestQtyPerPage = nb
            useItems.state.pagination.meta.requestedPage = 1
            getResourcesPagination()
        }

        function getPageResourcesPagination(page) {
            useItems.state.pagination.meta.requestedPage = page
            getResourcesPagination()
        }

        function getNextResourcesPagination() {
            if ( useItems.state.pagination.links.next !== null ) {
                useItems.state.pagination.meta.requestedPage = useItems.state.pagination.meta.curentPage + 1
                getResourcesPagination()
            }
        }

        function getPreviousResourcesPagination() {
            if ( useItems.state.pagination.links.prev !== null ) {
                useItems.state.pagination.meta.requestedPage = useItems.state.pagination.meta.curentPage - 1
                getResourcesPagination()
            }
        }

        function getResourcesPagination() {

            useItems.getResourcesPaginate()
            .then(() => {
                useItems.sortResources('name', 'string')
            })
            .catch((error) => {
                useItems.useErrors.useSetErrors(error.response.data)
            })
        }


        function getAllItems() {
            useItems.getItems()
            .then(() => {
                useItems.sortResources('name', 'string')
            })
            .catch((error) => {
                useItems.useErrors.useSetErrors(error.response.data)
            })
        }

        // Erase resource data before insertion in form
        function createResource() {

            // Define modal attributes
            state.modal.item.header = t('form.cards.items.headers.create')
            state.modal.item.action = 'create'

            // Open Modal component
            state.modal.item.componentEdit.toggle()

            // Erase error messages
            useItems.useErrors.useResetErrors()

            // Flush and Init model's attributes
            useItems.flushAttributes()
        }


        // Erase resource data before insertion in form
        function createPrice(item) {

            // Define modal attributes
            state.modal.price.header = t('form.cards.prices.headers.create')
            state.modal.price.action = 'create'

            // Open Modal component
            state.modal.price.componentEdit.toggle()

            // Erase error messages
            usePrices.useErrors.useResetErrors()

            // Flush and Init model's attributes
            usePrices.flushAttributes()
            usePrices.state.form.body.item_id = item.id
        }



        // Clone resource to update before insertion in form
        function editResource(resource) {

            // Define modal attributes
            state.modal.item.header = t('form.cards.items.headers.edit')
            state.modal.item.action = 'edit'

            // Open Modal component
            state.modal.item.componentEdit.toggle()

            // Erase error messages
            useItems.useErrors.useResetErrors()

            // Clone user's attributes
            useItems.state.form.body = objUtils.cloneObject(resource)
        }

        // Clone resource to update before insertion in form
        function editPrice(resource) {

            // Define modal attributes
            state.modal.price.header = t('form.cards.prices.headers.edit')
            state.modal.price.action = 'edit'

            // Open Modal component
            state.modal.price.componentEdit.toggle()

            // Erase error messages
            usePrices.useErrors.useResetErrors()

            // Clone user's attributes
            usePrices.state.form.body = objUtils.cloneObject(resource)
        }


        // Clone resource to update before insertion in form
        function showResource(resource) {

            // Define modal attributes
            state.modal.item.header = t('form.cards.items.headers.show')
            state.modal.item.action = 'show'

            // Open Modal component
            state.modal.item.componentShow.toggle()

            // Erase error messages
            useItems.useErrors.useResetErrors()

            state.itemToShow = {}
            state.itemInfo = ''

            state.itemToShow.id = resource.id
            state.itemToShow.name = resource.name
            state.itemToShow.description = resource.description

            state.itemInfo = resource.info

        }

        // Save new created or updated resource
        function saveResource() {

            if ( useItems.state.form.body.id == null ) {

                useItems.storeResource()
                .then(() => {
                    // Close Modal component
                    state.modal.item.componentEdit.toggle()
                })
                .catch((error) => {
                    useItems.useErrors.useSetErrors(error.response.data)
                })
            } else {
                useItems.updateResource()
                .then(() => {
                    // Close Modal component
                    state.modal.item.componentEdit.toggle()
                })
                .catch((error) => {
                    useItems.useErrors.useSetErrors(error.response.data)
                })
            }
        }


        function deleteResource(resource) {

            if ( confirm(t('form.infos.delete.confirmation') + ` '${resource.name} ${resource.description}'`) ) {
                useItems.deleteResource(resource)
            }
        }

        // Format data
        const format = (date) => {
            const day = date.getDate();
            const month = date.getMonth() + 1;
            const year = date.getFullYear();

            return `${day}/${month}/${year}`
        }

        const handleInternalChangeValidityFrom = (date) => {
            // Do something
            // console.log(`Current selection - ${date}`);

            usePrices.state.form.body.validity_from = dateTime.formatIsoDate(date)

            console.log(`newFromDate - ${state.newFromDate}`)
            console.log(`sate.form.body - ${usePrices.state.form.body.validity_from}`)
        }

        const handleInternalChangeValidityTo = (date) => {
            // Do something
            // console.log(`Current selection - ${date}`);

            usePrices.state.form.body.validity_to = dateTime.formatIsoDate(date)

            console.log(`newToDate - ${state.newToDate}`)
            console.log(`sate.form.body - ${usePrices.state.form.body.validity_to}`)
        }

        // Return formated date to display
    /*
        function formatedDate(date) {
            //return dateTime.formatedDate(date, state.lang, "LLLL")
            return dateTime.formatedDate(date, locale.value, "LLLL")
        }
    */




        return {
            handleInternalChangeValidityFrom,
            handleInternalChangeValidityTo,
            utc,
            format,
            //formatedDate,
            dateTime,
            masterUser,
            useItems,
            usePrices,
            formUtilsComp,
            objUtils,
            state,
            createResource,
            editResource,
            showResource,
            saveResource,
            deleteResource,
            createPrice,
            editPrice,
            savePrice,
            deletePrice,
            setNbPerPageForPagination,
            getPageResourcesPagination,
            getNextResourcesPagination,
            getPreviousResourcesPagination,
            getPrices,
        }
    }
}

</script>


<style>
    @import '../../assets/css/logo.css';
</style>
