<template>
    <li class="nav-item">
        <a
            class="nav-link"
            v-bind:class="selected === quantity ? 'active text-decoration-underline' : 'text-secondary'"
            aria-current="page"
            href="#"
            @click="$emit('setNbPerPageForPagination', quantity)"
        >{{ quantity }}</a>
    </li>
</template>


<script>

export default {

    name: 'NavItem',

    props: {
        quantity: {
            type: Number,
            default: 10,
        },
        selected: {
            type: Number,
            default: 10,
        },

    },

}

</script>
